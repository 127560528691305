<template>
    <el-dialog
    v-model="dialogVisible"
    :title="type==0?'更新病害':'处理'"
    width="500"
    :before-close="handleClose"
    >
    <el-form :model="form" label-width="80px"  :rules="rules" ref="form">
        <el-form-item v-if="type==1" label="处理过程" prop="handle">
            <el-input v-model="form.handle" type="textarea"  />
        </el-form-item>
        <el-form-item v-else label="病害情况" prop="disease">
            <el-input v-model="form.disease" type="textarea" />
        </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submit('form')" >
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { site_updateAlarm } from '@/api/site-warning.js'
export default{
    emits:['OK'],
    data(){
        return{
            dialogVisible:false,
            type:0,
            form:{
                id:null,
                handle:null,
                status:0,
                disease:null,
            },
            rules:{
                handle:{ required: true, message: '请输入处理信息', trigger: 'blur' },
                disease:{ required: true, message: '请输入病害情况', trigger: 'blur' },
            }
        }
    },
    methods:{
        open(type,data){
            this.type = type
            this.dialogVisible = true
            this.$nextTick(()=>{
                this.$refs.form.resetFields()
                this.form.id = data.id
                if(type == 0){
                    this.form.disease = data.disease
                }else{
                    this.form.handle = null
                    this.form.status = data.status         
                }
            })
        },
        handleClose(done){
            done()
        },
        submit(formName){
            this.$refs[formName].validate((valid, fields) => {
                if (valid) {
                    let query = {}
                    if(this.type==0){
                        query = {
                            id:this.form.id,
                            disease:this.form.disease
                        }
                    }else{
                        query = {
                            id:this.form.id,
                            handle:this.form.handle,
                            status:1,
                        }
                    }
                    site_updateAlarm(query).then(r=>{
                        if(r.code == 200){
                            this.$message.success(`${this.type==1?'预警处理成功':'病害更新成功'}`)
                            this.$emit('OK',1)
                        }
                        this.dialogVisible = false
                    })
                } else {
                return false
                }
            })
        }
    }
}
</script>