<template>
    <div class="pageBox">
        <div class="mainBox">
            <div class="operBar">
                <el-date-picker
                v-model="query.day"
                value-format="YYYY-MM-DD"
                type="date"
                placeholder="选择日期"
                />
                
                <!-- <el-tree-select 
                class="marginCss"
                v-model="query.zoneid" 
                node-key="envId" 
                :data="stations" 
                check-strictly 
                :props="defaultProps"/> -->
                <el-select v-model="query.status" class="marginCss" placeholder="选择状态">
                    <el-option
                    v-for="item in status"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
                <el-button type="primary" class="marginCss" @click="CX(0)">查询</el-button>
            </div>
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="relicname" label="文物名称" />
                <el-table-column prop="material" label="文物材质" />
                <el-table-column prop="createtime" label="报警时间" />
                <el-table-column prop="detail" label="报警原因" />
                <el-table-column prop="positionname" label="报警位置" />
                <el-table-column prop="disease" label="病害信息" />
                <el-table-column label="报警状态">
                    <template #default="scope">
                        <div v-if="scope.row.status==1">
                            <el-tag type="success">已处理</el-tag>-{{ scope.row.handle }}
                        </div>
                        <el-tag v-else type="danger">{{scope.row.status==2?'超时':''}}未处理</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button link type="primary" size="small" @click="statusOper(0,scope.row)">更新病害</el-button>
                        <el-button link v-if="scope.row.status==0||scope.row.status==2" type="primary" size="small" @click="statusOper(1,scope.row)">处理</el-button>
                        <!-- <el-button link v-if="scope.row.status==0" type="primary" size="small">查询预案</el-button> -->
                        <!-- <el-button link v-if="scope.row.status==1"  type="primary" size="small">查看结果</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
            class="pagination"
            small
            layout="total, prev, pager, next"
            :total="total"
            :page-size="query.size"
            :current-page="query.page"
            @current-change="CurrentChange"
            />
        </div>
        <statusOper ref="statusOper" @OK="CX"/>
    </div>
</template>
<script>
import { site_envList } from '@/api/site-home.js'
import { site_alarms } from '@/api/site-warning.js'
import { mapGetters } from 'vuex'
import statusOper from './Modules/statusOper.vue'
    export default{
      components:{
        statusOper
      },
      computed:{
        ...mapGetters(['siteId'])
      },
      data(){
        return{
            stations:[],
            status:[
                {label:'已处理',value:1},
                {label:'未处理',value:0}, 
            ],
            query:{
                page:1,
                size:10,
                day:null,
                status:0,
            },
            total:4,
            defaultProps:{
                children: 'children',
                label: 'envName',
            },
            tableData:[]
        }
      },
      mounted(){
        this.baseData()
       
      },
      methods:{
        CurrentChange(page){
            this.query.page = page
            this.CX(1)
        }, 
        baseData(){
            let minQuery2 = {
                'siteId':this.siteId,
                'envId':this.siteId,
                'isSelf':true,
                'isTree':true
            }
            site_envList(minQuery2).then(res=>{
                this.stations = res.data
                this.query.zoneid = res.data[0].envId
                this.CX(0)
            })
        },
        CX(type){
            if(type==0){
                this.query.page = 1
            }
            site_alarms(this.query).then(r=>{
                this.tableData = r.data.data

                this.total = r.data.pageInfo.total
            })
        },
        statusOper(type, data){
            this.$refs.statusOper.open(type , data)
        }
      },
       
    }
</script>
<style lang="scss" scoped>
.pageBox{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}
.mainBox{
    width:100%;
    padding:20px;
    background-color:white;
    box-sizing:border-box;
    border-radius:10px 10px 0px 0px;
}
.operBar{
    width:100%;
    height:50px;
    box-sizing:border-box;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    .marginCss{
        margin-left:20px;
    }
}
.pagination{
    margin-top: 5px;
    display: flex;
    justify-content: center;
}
</style>